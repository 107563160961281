import * as React from "react";

export function ProfitIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M16.645 6.194a5.012 5.012 0 00-3.628 1.55c.323.284.531.695.531 1.16v6.283a5.006 5.006 0 003.097 1.071 5.032 5.032 0 100-10.064z"
        fill="#FFC500"
      />
      <path d="M1.935 8.903H12v3.871H1.935v-3.87z" fill="#FB2B3A" />
      <path
        d="M12.387 22.452H9.29v-5.42h3.097v5.42zM8.516 22.452H5.42v-2.323h3.097v2.323zM4.645 22.452H1.548v-2.323h3.097v2.323zM8.516 19.355H5.42v-2.323h3.097v2.323zM4.645 19.355H1.548v-2.323h3.097v2.323zM8.516 16.258H5.42v-2.322h3.097v2.322zM12.387 16.258H9.29v-2.322h3.097v2.322zM4.645 16.258H1.548v-2.322h3.097v2.322z"
        fill="#FFC500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.387 13.161H1.548V8.516h10.84v4.645zm-.774-3.87h-9.29v3.096h9.29V9.29z"
        fill="#2D2D2D"
      />
      <path
        d="M16.645 8.516c.64 0 1.162.521 1.162 1.161h.774c0-.934-.666-1.716-1.549-1.896v-.813h-.774v.774H14.71v1.935c0 1.068.868 1.936 1.935 1.936.64 0 1.162.52 1.162 1.161v1.162h-1.162c-.64 0-1.161-.522-1.161-1.162h-.774a1.94 1.94 0 001.548 1.897v.813h.774v-.774h1.549v-1.936a1.938 1.938 0 00-1.936-1.935c-.64 0-1.161-.521-1.161-1.162v-1.16h1.161z"
        fill="#2D2D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.94 4.515l-.528.453C22.608 6.316 24 8.714 24 11.226c0 4.055-3.3 7.355-7.355 7.355-.941 0-1.85-.177-2.71-.519v4.002A1.938 1.938 0 0112 24H1.935A1.938 1.938 0 010 22.064V8.904c0-1.068.868-1.936 1.935-1.936h8.77a7.679 7.679 0 012.174-2l-.53-.453A5.43 5.43 0 0110.453.387V0h.387c.893 0 1.36.497 1.733.897.342.364.612.651 1.17.651.557 0 .827-.287 1.168-.651.375-.4.84-.897 1.734-.897.893 0 1.36.497 1.734.896.341.365.611.652 1.17.652.558 0 .828-.287 1.17-.651.374-.4.84-.897 1.734-.897h.387v.387c0 1.59-.692 3.094-1.9 4.128zm-8.932-3.09c-.246-.261-.454-.484-.76-.588a4.656 4.656 0 001.606 3.09l.838.718h5.907l.837-.718a4.654 4.654 0 001.607-3.09c-.306.104-.514.326-.76.587l-.001.002c-.375.4-.84.897-1.734.897s-1.36-.497-1.735-.897c-.341-.364-.611-.652-1.17-.652-.557 0-.827.288-1.168.652-.374.4-.84.897-1.733.897-.894 0-1.36-.497-1.734-.897zm1.153 20.64c0 .64-.52 1.16-1.161 1.16H1.935c-.64 0-1.16-.52-1.16-1.16V8.902c0-.64.52-1.161 1.16-1.161H12c.64 0 1.161.521 1.161 1.161v13.161zm.774-4.85a6.51 6.51 0 002.71.591 6.588 6.588 0 006.58-6.58c0-2.379-1.4-4.648-3.578-5.807h-6.003a6.91 6.91 0 00-1.948 1.549H12c1.067 0 1.935.868 1.935 1.935v8.311z"
        fill="#2D2D2D"
      />
      <path
        d="M20.203 14.784a5.066 5.066 0 01-.444.396l.479.607a5.743 5.743 0 00.855-.829l-.592-.498c-.094.111-.192.22-.298.324zM20.755 7.124l-.047-.047-.54.555.035.036a5.022 5.022 0 01.573 6.433l.635.443a5.795 5.795 0 00-.656-7.42zM10.065 10.839h.774v.774h-.774v-.774zM8.516 10.839h.774v.774h-.774v-.774zM6.968 10.839h.774v.774h-.774v-.774z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
