import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Button,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { EmailIcon, LockIcon } from "../../assets/icons";
import { LinkIcon } from '@chakra-ui/icons'
import { resetPassword, updatePassword } from "../../utils/fetch";
import { IconText } from "../Layout/IconText";

export const ResetPasswordModal = React.forwardRef(
  ({childRef}, ref): JSX.Element => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [state, setState] = useState({ email: "", password: "", code: "" });
    const [canSubmit, setCanSubmit] = useState(false);
    const [showPasswordAndCodeFields, setShowPasswordAndCodeFields] = useState(false);
    const history = useHistory();
    const resetPasswordMutation = useMutation((data: { email: string; }) => {
      return resetPassword(data.email)
    });
    const updatePasswordMutation = useMutation((data: { email: string; password: string; code: string }) => {
      return updatePassword(data.email, data.password, data.code)
     }
    );

    useEffect(() => {
      return () => {
        setShowPasswordAndCodeFields(false);
      }
    }, []);

    useEffect(() => {
      if (resetPasswordMutation.isSuccess) {
        setShowPasswordAndCodeFields(true);
        setCanSubmit(false);
      }
    }, [history, resetPasswordMutation.isSuccess]);

    useEffect(() => {
      if (updatePasswordMutation.isSuccess) {
        setShowPasswordAndCodeFields(false);
        onClose();
        childRef?.current?.open();
      }
    }, [history, updatePasswordMutation.isSuccess, onClose, childRef]);

    useEffect(() => {
      function checkProperties(obj: Object) {
        if (!state.email) return false;

        if (showPasswordAndCodeFields) {
          return state.password && state.code;
        }

        return true;
      }
      if (checkProperties(state)) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    }, [state]);

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setState((prev) => ({ ...prev, [name]: value }));
    };

    React.useImperativeHandle(ref, () => ({
      open() {
        onOpen();
      },
    }));

    const handleChangePassword = async () => {
      showPasswordAndCodeFields ? updatePasswordMutation.mutate(state) : resetPasswordMutation.mutate(state);
    };

    const close = () => {
      setShowPasswordAndCodeFields(false);
      onClose()
    }

    return (
      <>
        <Modal isOpen={isOpen} onClose={close}>
          <ModalOverlay />
          <ModalContent alignItems="center">
            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              boxShadow="0px 1px 10px rgba(0, 0, 0, 0.05)"
              padding="28px"
            >
              <Text
                textAlign="center"
                textStyle="subtitle1"
                fontWeight="bold"
                w="100%"
              >
                Reset Account Password
              </Text>
              <ModalCloseButton />
            </Box>
            <ModalBody w="100%" padding="18px 48px">
              <VStack marginBottom="20px" alignItems="flex-start">
                <IconText Icon={<EmailIcon />} text="Email" />
                <Input
                  borderRadius="6px"
                  size="sm"
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Email"
                  type="email"
                  _placeholder={{ color: "gray.300" }}
                  name="email"
                  onChange={handleChange}
                />
              </VStack>
              {showPasswordAndCodeFields && <VStack marginBottom="20px" alignItems="flex-start">
                <IconText Icon={<LockIcon />} text="Password" />
                <Input
                  type="password"
                  borderRadius="6px"
                  size="sm"
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Password"
                  _placeholder={{ color: "gray.300" }}
                  name="password"
                  onChange={handleChange}
                />
              </VStack>}
              {showPasswordAndCodeFields && <VStack marginBottom="20px" alignItems="flex-start">
                <IconText Icon={<LinkIcon />} text="Code Sent By Email" />
                <Input
                  borderRadius="6px"
                  size="sm"
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Code"
                  type="text"
                  _placeholder={{ color: "gray.300" }}
                  name="code"
                  onChange={handleChange}
                />
              </VStack>}
              <Button
                disabled={!canSubmit}
                isLoading={resetPasswordMutation.isLoading || updatePasswordMutation.isLoading}
                loadingText="Submitting"
                onClick={handleChangePassword}
                isFullWidth
              >
                {showPasswordAndCodeFields ? 'Change Password' : 'Send Code Email'}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);
