import { createStandaloneToast } from "@chakra-ui/react";
import { theme } from "../styles/theme";

export const successToast = createStandaloneToast({
  theme,
  defaultOptions: {
    title: "Success",
    position: "bottom-right",
    isClosable: true,
    status: "success",
    variant: "top-accent",
  },
});

export const errorToast = createStandaloneToast({
  theme,
  defaultOptions: {
    title: "An error occurred.",
    status: "warning",
    position: "bottom-right",
    isClosable: true,
    variant: "top-accent",
  },
});
