import create from 'zustand';
import { Country, userType } from '../typings/user';
import { fetchCountries } from '../utils/fetch';
import { successToast } from '../components/toast';

export type AuthSlice = {
  token: string | null;
  user: userType | null;
  countries: Country[] | undefined;
  initApp: () => Promise<void>;
  updateProfileData: (user: userType) => void;
  logout: (to: string) => void;
};

export const useAuth = create<AuthSlice>((set, get) => ({
  token: null,
  user: null,
  countries: undefined,
  // Actions
  initApp: async () => {
    console.log('INVOKED');
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user')) as userType;
    const countries = await fetchCountries();
    set({
      token,
      user,
      countries: countries?.map((country) => ({
        ...country,
        label: country.name,
      })),
    });
  },
  updateProfileData: (user) => {
    successToast({ description: 'Profile Updated Successfully' });
    localStorage.setItem('user', JSON.stringify(user));
    set({ user });
  },
  logout: (to?: string) => {
    localStorage.clear();
    window.location.href = to || '/home';
  },
}));

export { useAuth as AuthAction };
