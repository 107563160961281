import * as React from "react";

type Props = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

export function ArrowIcon(props: Props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.115 1.885L4 1l5 5-5 5-.885-.885L7.23 6 3.115 1.885z"
        fill={props.color ? props.color : "#FFC500"}
        stroke={props.color ? props.color : "#FFC500"}
        strokeWidth={0.5}
      />
    </svg>
  );
}
