import React, { FunctionComponent } from "react";
import { Box, Circle, Divider, Flex, Text } from "@chakra-ui/react";
import { FacebookIcon } from "../../assets/icons/Facebook";
import { LinkedinIcon } from "../../assets/icons/Linkedin";
import { InstagramIcon } from "../../assets/icons/Instagram";

export const Footer: FunctionComponent = (): JSX.Element => {
  return (
    <footer>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        height={["135px", "80px"]}
        padding={["32px 18px", "32px 64px"]}
        backgroundColor="black"
        direction={["column", "row"]}
      >
        <Box
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          width="110px"
          order={[null, 2]}
        >
         <a href="https://www.facebook.com/Leoshippingusa-104087242181594">  <FacebookIcon /></a>
          <Circle w="4px" h="4px" backgroundColor="white" />
          <a href="https://www.instagram.com/leoshippingusa/">   <InstagramIcon /></a>
        
        </Box>
        <Divider display={[null, "none"]} borderColor="white" />
        <Box
          width={["100%", "500px", "500px"]}
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          order={[null, 1]}
        >
          <Text color="white" textStyle="body1">
            © 2021 LEO Shipping Hub, Inc.
          </Text>
          {/* <Text color="white" textStyle="body1">
            Privacy
          </Text> */}
          <Circle w="4px" h="4px" backgroundColor="white" />
          <Text color="white" textStyle="body1">
         <a href="/terms">Terms and Privacy</a>  
          </Text>
          <Circle w="4px" h="4px" backgroundColor="white" />
          <Text color="white" textStyle="body1">
            Whatsapp +1 (949) 312-8587 
          </Text>
        </Box>
      </Flex>
    </footer>
  );
};
