import * as React from "react";

export function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3332 5.33334H11.9998C12.7332 5.33334 13.3332 5.93334 13.3332 6.66667V13.3333C13.3332 14.0667 12.7332 14.6667 11.9998 14.6667H3.99984C3.2665 14.6667 2.6665 14.0667 2.6665 13.3333V6.66667C2.6665 5.93334 3.2665 5.33334 3.99984 5.33334H4.6665V4.00001C4.6665 2.16 6.15984 0.666672 7.99984 0.666672C9.83984 0.666672 11.3332 2.16 11.3332 4.00001V5.33334ZM7.99984 2.00001C6.89317 2.00001 5.99984 2.89334 5.99984 4.00001V5.33334H9.99984V4.00001C9.99984 2.89334 9.1065 2.00001 7.99984 2.00001ZM3.99984 13.3333V6.66667H11.9998V13.3333H3.99984ZM9.33317 10C9.33317 10.7333 8.73317 11.3333 7.99984 11.3333C7.2665 11.3333 6.6665 10.7333 6.6665 10C6.6665 9.26667 7.2665 8.66667 7.99984 8.66667C8.73317 8.66667 9.33317 9.26667 9.33317 10Z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
