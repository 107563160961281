import { Box } from "@chakra-ui/react";
import React, { FunctionComponent, ReactNode } from "react";
import { Footer } from "../Footer/Footer";
import { NavigationBar } from "../NavigationBar/NavigationBar";

type Props = {
  children: ReactNode;
};

export const MainLayout: FunctionComponent<Props> = ({
  children,
}): JSX.Element => {
  return (
    <>
      <NavigationBar />
      <Box minHeight="100vh">{children}</Box>
      <Footer />
    </>
  );
};
