export const COLORS = {
  primary: "#FFC600",
  black: "#2D2D2D",
  white: "#fff",
  gray: {
    50: "#2f4f4f",
    100: "#FFC600",
    200: "#F0972B",
    300: "#9A9A9A",
    400: "#FCFCFC",
  },
  green: {
    50: "#339200",
  },
  red: {
    50: "#9D0000",
    100: "#b50000",
  },
  yellow: {
    50: "#FFFdF8",
  },
  orange: {
    50: "#F0972B",
  },
};
