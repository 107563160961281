import React, { FunctionComponent, ReactNode } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";

type Props = {
  Icon: ReactNode | JSX.Element;
  text: string;
  disableMargin: boolean;
} & BoxProps;

export const IconText: FunctionComponent<Props> = ({
  text,
  Icon,
  disableMargin,
  ...props
}): JSX.Element => {
  return (
    <Box
      style={disableMargin && { margin: 0 }}
      marginTop={disableMargin ? "0px" : "10px"}
      d="flex"
      alignItems="center"
    >
      {Icon}
      <Text {...props}>{text}</Text>
    </Box>
  );
};

IconText.defaultProps = {
  textStyle: "body1",
  marginLeft: "6px",
};
