import * as React from "react";

export function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.33333H12.6667V12.6667H8V14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2H8V3.33333ZM5.60667 6.27333L4.66667 5.33333L2 8L4.66667 10.6667L5.60667 9.72L4.55333 8.66667H10V7.33333H4.55333L5.60667 6.27333Z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
