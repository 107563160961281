import { Box, Spinner as Spin } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

const Spinner: FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        size="middle"
      >
        <Spin size="lg" color="gray.100" />
      </Box>
    </div>
  );
};
export default Spinner;
