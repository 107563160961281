import {
  Box,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Button,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { EmailIcon, LockIcon, PersonIcon } from '../../assets/icons';
import { useAuth } from '../../store/user';
import { register } from '../../utils/fetch';
import { IconText } from '../Layout/IconText';

export const SignupModal = React.forwardRef(
  ({ childRef }, ref): JSX.Element => {
    const {
      isOpen: isOpenSignup,
      onOpen: onOpenSignup,
      onClose: onCloseSignup,
    } = useDisclosure();
    const [state, setState] = useState({
      email: '',
      password: '',
      phone_number: '',
      name: '',
    });
    const [canSubmit, setCanSubmit] = useState(false);
    const history = useHistory();
    const mutation = useMutation(
      (data: {
        email: string;
        password: string;
        phone_number: string;
        name: string;
      }) => register(data.email, data.password, data.phone_number, data.name)
    );
    const { countries, user, updateProfileData } = useAuth((store) => store);

    useEffect(() => {
      if (mutation.isSuccess) {
        onCloseSignup();
        window.location.href = '/dashboard';
      }
    }, [history, mutation.isSuccess, onCloseSignup]);

    useEffect(() => {
      function checkProperties(obj: Object) {
        for (var key in obj) {
          if (obj[key] === '') {
            return false;
          }
        }
        return true;
      }
      if (checkProperties(state)) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    }, [state]);

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setState((prev) => ({ ...prev, [name]: value }));
    };

    React.useImperativeHandle(ref, () => ({
      openSignup() {
        onOpenSignup();
      },
    }));

    const handleSignup = async () => {
      mutation.mutate(state);
    };

    const onLoginClick = () => {
      onCloseSignup();
      childRef?.current?.open();
    };

    return (
      <>
        <Modal isOpen={isOpenSignup} onClose={onCloseSignup}>
          <ModalOverlay />
          <ModalContent alignItems='center'>
            <Box
              d='flex'
              justifyContent='center'
              alignItems='center'
              width='100%'
              boxShadow='0px 1px 10px rgba(0, 0, 0, 0.05)'
              padding='28px'
            >
              <Text
                textAlign='center'
                textStyle='subtitle1'
                fontWeight='bold'
                w='100%'
              >
                Sign up and Start Shipping
              </Text>
              <ModalCloseButton />
            </Box>
            <ModalBody w='100%' padding='18px 48px'>
              <VStack alignItems='flex-start'>
                <IconText Icon={<PersonIcon />} text='Full Name' />
                <Input
                  borderRadius='6px'
                  size='sm'
                  isRequired
                  color='rgba(45, 45, 45, 0.548)'
                  borderColor='rgba(45, 45, 45, 0.25)'
                  placeholder='Full Name'
                  type='name'
                  _placeholder={{ color: 'gray.300' }}
                  name='name'
                  onChange={handleChange}
                />
              </VStack>
              <VStack alignItems='flex-start'>
                <IconText Icon={<EmailIcon />} text='Email' />
                <Input
                  borderRadius='6px'
                  size='sm'
                  isRequired
                  color='rgba(45, 45, 45, 0.548)'
                  borderColor='rgba(45, 45, 45, 0.25)'
                  placeholder='Email Address'
                  type='email'
                  _placeholder={{ color: 'gray.300' }}
                  name='email'
                  onChange={handleChange}
                />
              </VStack>
              <HStack w='100%'>
                {/* <VStack w="30%" alignItems="flex-start">
                <IconText Icon={<PersonIcon />} text="Code" />
                <Select
                  style={{ height: 35 }}
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Code"
                  name="code"
                  // onChange={handleChange}
                >
                  {countries?.map((country) => (
                    <option key={country.id} value={country.phonecode}>
                      {`${country.code}  (${country.phonecode})`}
                    </option>
                  ))}
                </Select>
              </VStack> */}
                <VStack width='100%' alignItems='flex-start'>
                  <IconText Icon={<PersonIcon />} text='Mobile Number' />
                  <Input
                    width='100%'
                    borderRadius='6px'
                    size='sm'
                    isRequired
                    color='rgba(45, 45, 45, 0.548)'
                    borderColor='rgba(45, 45, 45, 0.25)'
                    placeholder='e.g 0100-46-95-714'
                    type='number'
                    _placeholder={{ color: 'gray.300' }}
                    name='phone_number'
                    onChange={handleChange}
                  />
                </VStack>
              </HStack>
              <VStack alignItems='flex-start'>
                <IconText Icon={<LockIcon />} text='Password' />
                <Input
                  type='password'
                  borderRadius='6px'
                  size='sm'
                  isRequired
                  color='rgba(45, 45, 45, 0.548)'
                  borderColor='rgba(45, 45, 45, 0.25)'
                  placeholder='8+ characters'
                  _placeholder={{ color: 'gray.300' }}
                  name='password'
                  onChange={handleChange}
                />
              </VStack>
              <Button
                disabled={!canSubmit}
                marginTop='6'
                isLoading={mutation.isLoading}
                loadingText='Submitting'
                onClick={handleSignup}
                isFullWidth
              >
                Sign up
              </Button>
              <HStack
                cursor='pointer'
                marginTop='16px'
                justifyContent='center'
                spacing={1}
              >
                <Text color='gray.300' textStyle='body1'>
                  Already a member?
                </Text>
                <Text
                  color='gray.300'
                  textDecoration='underline'
                  textStyle='body1'
                  onClick={onLoginClick}
                >
                  Login
                </Text>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);
