import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Collapse,
  Button,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  ArrowIcon,
  LogoIcon,
  LogoutIcon,
  MenuIcon,
  NotificationButtonIcon,
  SettingsIcon,
} from '../../assets/icons';
import { Link } from 'react-router-dom';
import { AuthModal } from '../Modals/Auth';
import { useAuth } from '../../store/user';
import { SignupModal } from '../Modals/SIgnup';
import { ResetPasswordModal } from '../Modals/ResetPassword';
import { AvatarIcon } from '../../assets/icons/Avatar';

const RenderLinks = ({
  isMobile,
  childRef,
  Links,
  onClose,
  signupRef,
}: {
  isMobile?: boolean;
  childRef: React.MutableRefObject<undefined>;
  signupRef: React.MutableRefObject<undefined>;
  Links: string[];
  onClose?: () => void;
}) => {
  return (
    <>
      {Links.map((link, index) => {
        const slug = link.replace(/\s+/g, '-').toLowerCase();
        if (index === 4) {
          return (
            <Link onClick={onClose} key={index} to={isMobile && `/${slug}`}>
              <Button
                onClick={() => {
                  !isMobile && signupRef?.current?.openSignup();
                }}
                maxW='100px'
                key={link}
              >
                {link}
              </Button>
            </Link>
          );
        } else if (index === 3 && !isMobile) {
          return (
            <Button
              onClick={() => childRef?.current?.open()}
              key={link}
              variant='outline'
            >
              {link}
            </Button>
          );
        } else {
          return (
            <Link onClick={onClose} key={index} to={`/${slug}`}>
              <Button key={link} variant='link'>
                {link}
              </Button>
            </Link>
          );
        }
      })}
    </>
  );
};

export const NavigationBar: FunctionComponent = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const childRef = React.useRef();
  const signupRef = React.useRef();
  const resetPasswordRef = React.useRef();

  const loc = useLocation();

  const [open, setOpen] = useState(false);
  const { user, logout } = useAuth((store) => store);

  const Links = user
    ? ['Dashboard', 'Shipping Rate', 'Orders']
    : loc.pathname == '/sign-up' || loc.pathname == '/login'
    ? ['Home', 'Shipping Rate']
    : ['Home', 'Shipping Rate', , 'Login', 'Sign up'];

  const toggleMenu = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Box
        p={['0px', '8px', '26px 56px']}
        boxShadow=' 0px 2px 10px rgba(0, 0, 0, 0.06)'
        bg='white'
        px={4}
      >
        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={user ? 'flex-start' : 'space-between'}
        >
          <Flex
            _hover={{ opacity: 0.5 }}
            cursor='pointer'
            alignItems={'center'}
            marginRight='64px'
            maxW={['60px', '88px', '110px']}
          >
            <Link to='/home'>
              <LogoIcon />
            </Link>
          </Flex>
          <Flex w='100%' justifyContent={user ? 'space-between' : 'flex-end'}>
            <HStack spacing={8} alignItems={'center'}>
              <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', md: 'flex' }}
              >
                <RenderLinks
                  signupRef={signupRef}
                  Links={Links}
                  childRef={childRef}
                />
              </HStack>
            </HStack>
            {user && (
              <HStack
                cursor='pointer'
                onClick={toggleMenu}
                display={['none', 'flex']}
              >
                {/* <NotificationButtonIcon style={{ marginRight: 18 }} /> */}
                <Avatar size='xs' bg='black' src='https://bit.ly/broken-link' />
                <Menu isOpen={open}>
                  <MenuButton>{user.name}</MenuButton>
                  <ArrowIcon
                    style={{ transform: 'rotate(90deg)' }}
                    color='black'
                  />
                  <MenuList minWidth='150px' borderColor='white'>
                    <MenuItem>
                      <SettingsIcon />
                      <Link to='/profile'>
                        <Text marginLeft='6px' textStyle='body2'>
                          Profile Settings
                        </Text>
                      </Link>
                    </MenuItem>
                    <Divider borderColor='gray.400' />
                    <MenuItem onClick={() => logout()}>
                      <LogoutIcon />
                      <Text marginLeft='6px' textStyle='body2'>
                        Logout
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            )}
          </Flex>

          {user ? (
            <Box
              aria-label={'Open Menu'}
              display={{ md: !isOpen ? 'none' : 'flex' }}
              onClick={isOpen ? onClose : onOpen}
              cursor='pointer'
            >
              <AvatarIcon />
            </Box>
          ) : (
            <IconButton
              bg='white'
              d='flex'
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <MenuIcon />}
              aria-label={'Open Menu'}
              display={{ md: !isOpen ? 'none' : 'flex' }}
              onClick={isOpen ? onClose : onOpen}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: '0!important' }}
        >
          <Stack alignItems='center' as={'nav'} spacing={4}>
            <RenderLinks
              signupRef={signupRef}
              onClose={onClose}
              Links={Links}
              childRef={childRef}
              isMobile
            />
          </Stack>
        </Collapse>
      </Box>
      <AuthModal
        ref={childRef}
        signupRef={signupRef}
        resetPasswordRef={resetPasswordRef}
      />
      <SignupModal
        ref={signupRef}
        childRef={childRef}
        resetPasswordRef={resetPasswordRef}
      />
      <ResetPasswordModal ref={resetPasswordRef} childRef={childRef} />
    </>
  );
};
