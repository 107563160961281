import React, { FunctionComponent, LazyExoticComponent, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Spinner from '../components/LoadingIndicators/Spinner';

type Props = {
  component: LazyExoticComponent<FunctionComponent<{}>>;
  path: string | string[];
  exact: boolean;
  requestedRole: Array<string | null | undefined>;
  name: string;
};

const Protected = ({
  component: Component,
  path,
  exact,
  requestedRole,
  ...rest
}: Props) => {
  const isAuthed = !!localStorage.getItem('token');

  const Authenticated = (props: any) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...rest} {...props} />
      </Suspense>
    );
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthed) {
          return <Authenticated props={props} />;
        } else {
          return <Authenticated props={props} />;
        }
      }}
    />
  );
};

export default Protected;
