export const getFormData = (object: any) =>
  Object.keys(object).reduce((formData, key) => {
    if (Array.isArray(object[key])) {
      if (object[key].length) {
        object[key].forEach((item: any) => {
          formData.append(`${key}[]`, item);
        });
      }
    } else {
      formData.append(key, object[key]);
    }
    return formData;
  }, new FormData());

export const isBlob = (file: any) => {
  return file?.preview?.startsWith("blob:")
}