import * as React from "react";

export function BoxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9969 3.9459C15.9782 3.7738 15.8713 3.62149 15.7114 3.5469L8.21141 0.0468984C8.07738 -0.0156328 7.92256 -0.0156328 7.78853 0.0468984L0.288531 3.5469C0.128719 3.62149 0.0217812 3.77377 0.0030625 3.9459C0.00265625 3.94949 0 3.99737 0 3.99999V12.5C0 12.7044 0.124469 12.8883 0.314312 12.9642L7.81431 15.9642C7.87391 15.9881 7.93697 16 8 16C8.06303 16 8.12609 15.9881 8.18569 15.9642L15.6857 12.9642C15.8755 12.8883 16 12.7044 16 12.5V3.99999C16 3.99737 15.9973 3.94946 15.9969 3.9459ZM8 1.05174L14.242 3.96468L11.8384 4.92612L5.47897 2.22824L8 1.05174ZM10.5205 5.45327L4.25969 2.79724L1.758 3.96468L8 6.46146L10.5205 5.45327ZM1 4.73849L7.5 7.33849V14.7615L1 12.1615V4.73849ZM8.5 7.33849V14.7615L15 12.1615V4.73849L8.5 7.33849Z"
        fill="black"
      />
    </svg>
  );
}
