import { COLORS } from "../colors";

export const Button = {
  baseStyle: {
    fontWeight: 500,
    borderRadius: 20,
    maxHeight: 39,
    _focus: { boxShadow: "none" },
  },
  variants: {
    solid: { fontSize: "1rem" },
    outline: {
      color: COLORS.black,
      borderColor: COLORS.black,
      _hover: { backgroundColor: COLORS.yellow },
      fontSize: "1rem",
    },
    link: {
      color: COLORS.gray[50],
      fontSize: "1rem",
    },
  },
};
