/*
Font Sizes
xs=10, sm=12, md=14, lg=16, xl=18, 2xl=20, 3xl=24, 4xl=32
*/

export const fontSizes = {
  xs: "0.625rem", // 10
  sm: "0.75rem", // 12
  md: "0.875rem", // 14
  lg: "1rem", // 16
  xl: "1.125rem", // 18
  "2xl": "1.25rem", // 20
  "3xl": "1.5rem", //24
  "4xl": "2rem", //32
};
