import * as React from "react";

export function FacebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={7}
      height={13}
      viewBox="0 0 7 13"
      {...props}
    >
      <defs>
        <path id="prefix__a" d="M0 1h7v13H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
        <image
          width={7}
          height={13}
          y={1}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAYAAAABmx/yAAAAAXNSR0IArs4c6QAAAVhJREFUOE9jZMADjI3TWNlUDXWOLc84h66MEZc+i+jp1seXZBzBJY9Vo23kLPtDy1IP4HMNhkbL0FWcx1aFfsOnCSSHoZEY27Bq/P///39stllHzFJhZPz1/d9fFkZeDu4PGDZi02gbMVv9yMq0W8gGEqXROWGhyr6FCXdJ1mgbPV/5yLKke6gaQ0OZPb/YsvDwvPt/T1Dy/5mZab/Q/WgTO13jBxfzPd5bz8H+d3Bg+MeIKzAIRgc5Gm0i59iRZaN1xGQVsjQ6Bk4QJEsjI2MjMyNDQwNT6FUtxtevr4HjdP/++t/YQlXqp8gdkJoDBxj+MTA0/CMqAWCPRzTjsYWyedwcpVOLU++TnHIsI2conliR+YBkjTZhs+SPrk5/RLJGi5jJsieX5j0hWaNN5DzpoyuSn5Gs0TFsmuSB1dkvSNZoETVH4uTy1Jcka7QKmyt8fHXKO2SNAAp1uTb3lms0AAAAAElFTkSuQmCC"
        />
      </g>
    </svg>
  );
}
