import { extendTheme } from "@chakra-ui/react";

import { COLORS } from "./colors";
import { Button } from "./components";
import { fontSizes, letterSpacings } from "./foundation";

export const theme = extendTheme({
  colors: COLORS,
  fontSizes,
  letterSpacings,
  textStyles: {
    h1: {
      fontSize: fontSizes["4xl"],
      fontWeight: "bold",
      letterSpacing: letterSpacings.tight,
    },
    h2: {
      fontSize: fontSizes["3xl"],
      letterSpacing: letterSpacings.tight,
    },
    subtitle1: {
      fontSize: fontSizes["2xl"],
      letterSpacing: letterSpacings.tight,
    },
    subtitle2: {
      fontSize: fontSizes["xl"],
      letterSpacing: letterSpacings.tight,
    },
    body: {
      fontSize: fontSizes["lg"],
      letterSpacing: letterSpacings.tight,
    },
    body1: {
      fontSize: fontSizes["md"],
      letterSpacing: letterSpacings.tight,
    },
    body2: {
      fontSize: fontSizes["sm"],
      letterSpacing: letterSpacings.tight,
    },
    caption: {
      fontSize: fontSizes["xl"],
      letterSpacing: letterSpacings.tight,
    },
    overline: {
      fontSize: fontSizes["xs"],
      letterSpacing: letterSpacings.tight,
    },
  },
  components: {
    Button,
  },
  Box: {
    baseStyle: {
      borderRadius: 10,
      minWidth: 100,
      backgroundColor: "red",
    },
  },
});
