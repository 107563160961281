import * as React from "react";

export function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6668 4C14.6668 3.26666 14.0668 2.66666 13.3335 2.66666H2.66683C1.9335 2.66666 1.3335 3.26666 1.3335 4V12C1.3335 12.7333 1.9335 13.3333 2.66683 13.3333H13.3335C14.0668 13.3333 14.6668 12.7333 14.6668 12V4ZM13.3335 4L8.00016 7.33333L2.66683 4H13.3335ZM8.00016 8.66666L2.66683 5.33333V12H13.3335V5.33333L8.00016 8.66666Z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
