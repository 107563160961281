import {
  Box,
  Checkbox,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Button,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { EmailIcon, LockIcon } from "../../assets/icons";
import { login } from "../../utils/fetch";
import { IconText } from "../Layout/IconText";

export const AuthModal = React.forwardRef(
  ({signupRef, resetPasswordRef}, ref): JSX.Element => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [state, setState] = useState({ email: "", password: "" });
    const [canSubmit, setCanSubmit] = useState(false);
    const history = useHistory();
    const mutation = useMutation((data: { email: string; password: string }) =>
      login(data.email, data.password)
    );

    useEffect(() => {
      if (mutation.isSuccess) {
        onClose();
        window.location.href = "/dashboard";
      }
    }, [history, mutation.isSuccess, onClose]);

    useEffect(() => {
      function checkProperties(obj: Object) {
        for (var key in obj) {
          if (obj[key] === "") {
            return false;
          }
        }
        return true;
      }
      if (checkProperties(state)) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    }, [state]);

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setState((prev) => ({ ...prev, [name]: value }));
    };

    React.useImperativeHandle(ref, () => ({
      open() {
        onOpen();
      },
    }));

    const handleLogin = async () => {
      mutation.mutate(state);
    };

    const onSignupClick = () => {
      onClose();
      signupRef?.current?.openSignup()
    }

    const onForgetPasswordClick = () => {
      onClose();
      resetPasswordRef?.current?.open()
    }

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent alignItems="center">
            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              boxShadow="0px 1px 10px rgba(0, 0, 0, 0.05)"
              padding="28px"
            >
              <Text
                textAlign="center"
                textStyle="subtitle1"
                fontWeight="bold"
                w="100%"
              >
                Login to your account
              </Text>
              <ModalCloseButton />
            </Box>
            <ModalBody w="100%" padding="18px 48px">
              <VStack alignItems="flex-start">
                <IconText Icon={<EmailIcon />} text="Email" />
                <Input
                  borderRadius="6px"
                  size="sm"
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Email"
                  type="email"
                  _placeholder={{ color: "gray.300" }}
                  name="email"
                  onChange={handleChange}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <IconText Icon={<LockIcon />} text="Password" />
                <Input
                  type="password"
                  borderRadius="6px"
                  size="sm"
                  isRequired
                  color="rgba(45, 45, 45, 0.548)"
                  borderColor="rgba(45, 45, 45, 0.25)"
                  placeholder="Password"
                  _placeholder={{ color: "gray.300" }}
                  name="password"
                  onChange={handleChange}
                />
              </VStack>
              <HStack
                justifyContent="space-between"
                margin="10px 0px"
                alignItems="center"
              >
                <Checkbox
                  borderColor="#949494"
                  borderRadius="2.5px"
                  colorScheme="gray"
                  fontWeight="500"
                  isFocusable={false}
                  color="gray.300"
                >
                  Remember Me
                </Checkbox>
                <Text
                  cursor="pointer"
                  fontWeight="500"
                  textStyle="body1"
                  color="gray.300"
                  onClick={onForgetPasswordClick}
                >
                  Forgot Password?
                </Text>
              </HStack>
              <Button
                disabled={!canSubmit}
                isLoading={mutation.isLoading}
                loadingText="Submitting"
                onClick={handleLogin}
                isFullWidth
              >
                Login
              </Button>
              <HStack
                cursor="pointer"
                marginTop="16px"
                justifyContent="center"
                spacing={1}
              >
                <Text color="gray.300" textStyle="body1">
                  Not a member yet?
                </Text>
                <Text
                  color="gray.300"
                  textDecoration="underline"
                  textStyle="body1"
                  onClick={onSignupClick}
                >
                  Sign Up
                </Text>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);
