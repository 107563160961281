import * as React from "react";

export function CartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.156 9.333h-.001a.468.468 0 00-.468.467c0 .257.21.467.47.467h8.5a.468.468 0 110 .933h-8.5A1.405 1.405 0 013.75 9.8c0-.574.35-1.069.848-1.285L2.905.933H.47a.468.468 0 110-.933H3.28c.22 0 .41.152.458.365l.335 1.502h11.457a.47.47 0 01.45.595l-1.874 6.533a.468.468 0 01-.45.338H5.155zm8.147-.933l1.607-5.6H4.282l1.25 5.6h7.77z"
        fill="#949494"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.094 14a1.405 1.405 0 01-1.407-1.4c0-.772.631-1.4 1.407-1.4.775 0 1.406.628 1.406 1.4 0 .772-.63 1.4-1.406 1.4zm.468-1.4a.468.468 0 00-.937 0 .468.468 0 00.938 0zM12.719 14a1.405 1.405 0 01-1.406-1.4c0-.772.63-1.4 1.406-1.4.775 0 1.406.628 1.406 1.4 0 .772-.63 1.4-1.406 1.4zm.469-1.4a.468.468 0 00-.938 0 .468.468 0 00.938 0z"
        fill="#949494"
      />
    </svg>
  );
}
