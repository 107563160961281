import * as React from "react"

export function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <image
        width={14}
        height={14}
        x={93}
        fill="none"
        fillRule="evenodd"
        transform="translate(-93)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGOfPtRkwAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHKADAAQAAAABAAAAHAAAAABkvfSiAAADVElEQVRIDa2W20tUQRzHXZUuVhBhREoIZVg9RBeQSrthakIXiyKpnnoMegh6iv6CoMcSopdMiCBJLLopmN2sEKOCsAv1pshKF6HM9bJ9vmdnjmd3z549bH3hszPzm9/Mb65nNpKXong8Pg/TVjgB+yACcQgj+Ur34Ao8jkQiozL4imB74CX8L72mo8PeYHZEeVScpOICzDEO70mjEHZ2ppmzIosorIZCmIBzzPQ8aUIEq4UxkDSqvbAQnAEpDYt6xLcIauAZSJNwwIlGZjY8AektLEsMI7df2udDqVqTFoMNqonMl7EaxmFmFLnFUl+z4CIMQTMo+Hb4A9OwW05nQfoCxTnGcprRfin8BGkUSkGDeAPSNW1quQnyjfSXyaclOBdg3AkNUAH58BkeQBeHYpx0BFrhENyEYexaue/kpXrN8CpIfTA3YU/+xV4Gt0DL4qeHGHUq1V8BlCg1ZR24bpCimmGgcCrDoQPWglbhBryAadgIR6AW7uDbyIzekR8Er9zrFzhDOiiENpB0gtd7e1Ee2yp4ClI3JK0S5aQZZgu4jQYT8AM2pAazZerKYRCk/daulLICPlIFimrjg74kWiotewdL1U/qK+p0eK6bygYfJxsjroAz65vuudKYtGfZ1GscVvg42hgRBQySHVk2P/VhO7VtvP26tmwdfTCttnhbZ8hXGfsnn3o7GOfy+tS7Jl3qGOjZ2uxaUzLUrcF0FDSTuynVScVsM3yFdxssAH0gNiW1poBtHUkLLIZO6IKMCrz4nL4pOjxDax2ESuik3E6qQzQFuvgHQe/fAJyijT5xmRTXCFtACvq06aPcCjE5pkiDaoflflGwey/+iGYYNCKnD0Y9ROY4jS+R1kMF6CDo/mkJe/DRpy5N2GnmHtJJBfxovPQ0aa/GTDktofFzjCK0CKY/ZfbZu60lrQT9vdDSNIXuKaQjfdaBtkLPVI0C6oHU8yINQHnIvrK60ZeeKZ0NqReKnEZkqkAvtKSgTbAE9H9HA1Jq8ypbm01T6/RfphH6QdIK1imY+wXAcIxyM2gfpa+gF1w7bv3c3Te2THW6JnalfpM/zf5fJk0WQavhPmhE/yr9MeuGXd4oduSuDQedXL19O6AEctEwjXqgj5nFvB38BUL9qLm0xYaLAAAAAElFTkSuQmCC"
      />
    </svg>
  )
}
