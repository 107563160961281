import * as React from "react"

export function ScrollIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={39}
      viewBox="0 0 22 39"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width={20.5}
          height={37.5}
          x={0.75}
          y={0.75}
          stroke="#2D2D2D"
          strokeWidth={1.5}
          rx={10.25}
        />
        <circle cx={11} cy={11} r={3} fill="#2D2D2D" />
      </g>
    </svg>
  )
}
