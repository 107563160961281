import * as React from "react";

export function AvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#F6F6F6" />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <circle cx={16} cy={16} r={16} fill="#fff" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.868 6.147c-.235-.046-.47-.092-.702-.147h-1.157c-.237.058-.479.106-.72.154-.525.105-1.05.21-1.535.41-2.788 1.154-4.435 3.244-4.787 6.207-.308 2.592.62 4.783 2.587 6.53 2.345 2.084 5.982 2.46 8.732.936 2.84-1.574 4.43-4.715 3.878-7.837-.498-2.82-2.125-4.78-4.796-5.86-.475-.192-.987-.292-1.5-.393zM7.03 31.171c2.645 1.885 5.642 2.803 8.912 2.829 2.359-.015 4.58-.492 6.674-1.482 2.457-1.162 4.416-2.866 5.927-5.04.614-.886.597-.914.023-1.826l-.013-.019c-1.347-2.14-3.254-3.58-5.782-4.252-.898-.238-1.701-.267-2.54.319-1.117.781-2.45 1.108-3.838 1.166-1.825.077-3.499-.374-4.997-1.383-.375-.252-.768-.378-1.224-.287-.09.018-.18.034-.27.05-.142.024-.285.049-.425.082-2.961.716-5.09 2.402-6.403 5.036-.131.264-.077.506.076.745a14.583 14.583 0 003.88 4.062z"
          fill="#2D2D2D"
        />
      </g>
      <circle cx={26} cy={27} r={8} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 25v-1h9v1h-9zm0 2.5h9v-1h-9v1zm0 2.5h9v-1h-9v1z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
