import * as React from "react";

export function CallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.556 3H5.5c.306 0 .556.25.556.556 0 .694.11 1.36.316 1.983a.558.558 0 01-.139.567L5.011 7.328a8.416 8.416 0 003.661 3.66l1.222-1.221a.57.57 0 01.567-.134 6.337 6.337 0 001.983.317c.306 0 .556.25.556.556v1.938c0 .306-.25.556-.556.556A9.444 9.444 0 013 3.556C3 3.25 3.25 3 3.556 3zm1.41 1.111c.034.495.117.978.25 1.439l-.666.667a8.236 8.236 0 01-.422-2.106h.839zm5.478 6.678c.473.133.956.217 1.445.25v.828a8.572 8.572 0 01-2.111-.417l.666-.661z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
