import * as React from "react";

export function MenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={16}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#2D2D2D" d="M0 0h24v2H0zM0 7h24v2H0zM0 14h24v2H0z" />
    </svg>
  );
}
