import { lazy } from 'react';
const Home = lazy(() =>
  import('../pages/Home/Home').then(({ Home }) => ({ default: Home }))
);
const Terms = lazy(() =>
  import('../pages/Terms/Terms').then(({ Terms }) => ({ default: Terms }))
);
const ShippingRate = lazy(() =>
  import('../pages/Shipping-Rate').then(({ ShippingRate }) => ({
    default: ShippingRate,
  }))
);
const Profile = lazy(() =>
  import('../pages/Profile').then(({ Profile }) => ({
    default: Profile,
  }))
);
const Login = lazy(() =>
  import('../pages/Login').then(({ Login }) => ({
    default: Login,
  }))
);

const ForgetPassword = lazy(() =>
  import('../pages/ForgetPassword').then(({ ForgetPassword }) => ({
    default: ForgetPassword,
  }))
);

const Signup = lazy(() =>
  import('../pages/Signup').then(({ Signup }) => ({
    default: Signup,
  }))
);

const Dashboard = lazy(() =>
  import('../pages/Dashboard').then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);

const Label = lazy(() =>
  import('../pages/Label').then(({ Label }) => ({
    default: Label,
  }))
);

const Orders = lazy(() =>
  import('../pages/Orders').then(({ Orders }) => ({
    default: Orders,
  }))
);

const ShipForMe = lazy(() =>
  import('../pages/ShipForMe').then(({ ShipForMe }) => ({
    default: ShipForMe,
  }))
);

const BuyForMe = lazy(() =>
  import('../pages/BuyForMe').then(({ BuyForMe }) => ({
    default: BuyForMe,
  }))
);

const routes = [
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    requestedRole: [''],
  },
  {
    path: '/forget-password',
    exact: true,
    name: 'forgetPassword',
    component: ForgetPassword,
    requestedRole: [''],
  },
  {
    path: ['/', '/home'],
    exact: true,
    name: 'Home',
    component: Home,
    requestedRole: [''],
  },
  {
    path: ['/', '/terms'],
    exact: true,
    name: 'Terms',
    component: Terms,
    requestedRole: [''],
  },
  {
    path: ['/shipping-rate'],
    exact: true,
    name: 'ShippingRate',
    component: ShippingRate,
    requestedRole: [''],
  },

  {
    path: ['/profile'],
    exact: true,
    name: 'Profile',
    component: Profile,
    requestedRole: ['user'],
  },
  {
    path: '/sign-up',
    exact: true,
    name: 'Signup',
    component: Signup,
    requestedRole: [],
  },
  {
    path: ['/dashboard'],
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    requestedRole: ['user'],
  },
  {
    path: ['/label'],
    exact: true,
    name: 'Label',
    component: Label,
    requestedRole: ['user'],
  },
  {
    path: ['/orders'],
    exact: true,
    name: 'Orders',
    component: Orders,
    requestedRole: ['user'],
  },
  {
    path: ['/ship-for-me'],
    exact: true,
    name: 'ShipForMe',
    component: ShipForMe,
    requestedRole: ['user'],
  },
  {
    path: ['/buy-for-me'],
    exact: true,
    name: 'BuyForMe',
    component: BuyForMe,
    requestedRole: ['user'],
  },
  // {
  //   path: "*",
  //   exact: false,
  //   name: "Not Found",
  //   component: NotFound,
  //   requestedRole: [""],
  // },
];

export default routes;
