const URL = 'https://dashboard.leodistro.com/api/v1/';

export const endpoint = {
  countries: URL + 'countries',
  additionalOptions: URL + 'additional-options',
  login: URL + 'auth/login',
  register: URL + 'auth/register',
  me: URL + 'auth/me',
  validateUser: URL + 'auth/me/valid',
  shippingRate: URL + 'shipping/rate',
  createShipment: URL + 'shipping/shipment/',
  shipments: URL + 'shipping/shipments',
  purchase: URL + 'purchase',
  purchase_confirm: URL + 'purchase/confirm',
  resetPassword: URL + 'passwords/reset',
  updatePassword: URL + 'passwords/update',
  getCoupon: URL + 'coupons/',
  trackOrder: URL + 'tracking/',
};
