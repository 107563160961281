import * as React from "react";

export function PersonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.667A2.666 2.666 0 108 8a2.666 2.666 0 100-5.333zm1.4 2.666a1.4 1.4 0 10-2.8 0 1.4 1.4 0 002.8 0zm2.667 6c0-.426-2.087-1.4-4.067-1.4s-4.067.974-4.067 1.4v.734h8.134v-.734zm-9.4 0C2.667 9.56 6.22 8.667 8 8.667c1.78 0 5.333.893 5.333 2.666v2H2.667v-2z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
