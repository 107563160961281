import { getFormData } from './../helpers/getFormData';
import { AuthAction } from './../store/user';
import { errorToast, successToast } from './../components/toast';
import axios, { AxiosResponse } from 'axios';

import {
  additionalOption,
  Country,
  updateUserType,
  userType,
} from './../typings/user';
import { endpoint } from '../constants/endpoint';
import { Shipment, shippmentCalcType } from '../typings/shipment';

const instance = axios.create({});
instance.interceptors.request.use((req) => {
  req.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
  return req;
});

const logout = AuthAction.getState().logout;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout('/login');
    }
    if (error.response?.status === 422) {
      errorToast({
        description:
          error.response?.data.message === 'Record not found'
            ? 'Wrong Email or Password'
            : error.response?.data.errors.name ||
              error.response?.data.errors.email ||
              error.response?.data.errors.password ||
              error.response?.data.errors.phone_number ||
              'Something went wrong please try again later',
      });
    }
  }
);

export const fetchCountries = async (country_id: string = '') => {
  const response: AxiosResponse<Country[]> = await instance.get(
    endpoint.countries + `/${country_id}`
  );
  console.log({ response }, 'COUNTRIES');
  return response?.data;
};

export const fetchAdditionalOptions = async (country_id: string = '') => {
  const response: AxiosResponse<additionalOption[]> = await instance.get(
    endpoint.additionalOptions
  );
  return response?.data;
};

export const getCouponData = async (coupon: string = '') => {
  const response: AxiosResponse<any> = await instance.get(
    endpoint.getCoupon + coupon
  );
  return response?.data;
};
export const trackOrder = async (
  vendor: string = '',
  trackingId: string = ''
) => {
  const response: AxiosResponse<any> = await instance.get(
    endpoint.trackOrder + `${vendor}/${trackingId}`
  );
  return response?.data;
};

export const me = async () => {
  const response: AxiosResponse<userType> = await instance.get(endpoint.me);
  console.log({ response }, 'ME');
  return response?.data;
};

export const login = async (email: string, password: string) => {
  const response: AxiosResponse<{
    user: userType;
    token: string;
  }> = await instance.post(endpoint.login, { email, password });
  if (response?.data) {
    successToast({ description: 'Logged In Successfully' });
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }

  return response.data;
};

export const resetPassword = async (email: string) => {
  const response: AxiosResponse<any> = await instance.post(
    endpoint.resetPassword,
    { email }
  );

  if (response?.data?.status === 200) {
    successToast({
      description: 'Password reset mail has been sent with the code.',
    });
  }

  return response.data;
};

export const updatePassword = async (
  email: string,
  password: string,
  code: string
) => {
  const response: AxiosResponse<any> = await instance.post(
    endpoint.updatePassword,
    { email, password, code }
  );
  if (response?.data?.status === 201) {
    successToast({
      description: 'Password changed successfully. Please login to continue',
    });
  } else {
    errorToast({ description: 'Invalid code, please request another one' });
  }

  return response.data;
};

export const updateProfile = async (user: updateUserType) => {
  const formData = getFormData(user);
  const response: AxiosResponse<userType> = await instance.post(
    endpoint.me,
    formData
  );
  console.log({ response }, 'UPDATE PROFILE');
  return response?.data;
};

export const register = async (
  email: string,
  password: string,
  phone_number: string,
  name: string
) => {
  const response: AxiosResponse<{
    user: userType;
    token: string;
  }> = await instance.post(endpoint.register, {
    email,
    password,
    phone_number,
    name,
    password_confirmation: password,
  });
  if (response?.data) {
    successToast({ description: 'Logged In Successfully' });
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }

  return response.data;
};

type shippingType = {
  from: string;
  to: string;
  weight: string;
  width: string;
  height: string;
  length: string;
};

export const shippingRate = async (shipping: shippingType) => {
  const response: AxiosResponse<shippmentCalcType> = await instance.post(
    endpoint.shippingRate,
    {
      ...shipping,
    }
  );

  console.log(response.data, 'SHIPPING RATE');

  return response.data;
};

export const createShipment = async (shipment: any, provider: string) => {
  const response: AxiosResponse<Shipment> = await instance.post(
    `${endpoint.createShipment}${provider.split('*_*')[0]}`,
    shipment
  );

  console.log(response.data, 'SHIPPING RATE');
  if (response.data) {
    successToast({ description: 'Shippment Created Successfully' });
  }
  return response.data;
};

export const listShipments = async () => {
  const response: AxiosResponse<{ data: { shipments: Shipment[] } }> =
    await instance.get(endpoint.shipments);
  console.log({ response }, 'LIST MY SHIPMENTS');
  return response?.data?.data?.shipments;
};

export const purchase = async (shipment: any) => {
  const response: AxiosResponse<Shipment> = await instance.post(
    endpoint.purchase,
    shipment
  );
  return response?.data;
};
export const confirm_purchase = async (id: any) => {
  const response: AxiosResponse<Shipment> = await instance.post(
    endpoint.purchase_confirm,
    id
  );
  return response?.data;
};

export const listShipment = async (id?: number) => {
  if (id) {
    const response: AxiosResponse<{ data: Shipment }> = await instance.get(
      endpoint.shipments + `/${id}`
    );
    console.log({ response }, 'LIST MY SHIPMENTS');
    return response.data.data;
  }
};

export const updateShipment = async (
  status: 'paid' | 'approved' | 'cancelled' | 'rejected',
  id: number
) => {
  const response: AxiosResponse<{ data: Shipment }> = await instance.post(
    endpoint.shipments + `/${id}`,
    { status }
  );

  console.log(response.data, 'SHIPMENT UPDATED');
  if (response.data) {
    successToast({ description: `Order ${status} successfully` });
  }
  return response.data;
};
