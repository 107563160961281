import * as React from "react";

export function FlightLandIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.12 9.85999C13.9733 10.3933 13.4266 10.7067 12.8933 10.5667L1.84663 7.60666V4.15999L2.81329 4.41999L3.43329 5.96666L6.74663 6.85333V1.33333L8.03329 1.67333L9.87329 7.68666L13.4133 8.63333C13.9466 8.77999 14.26 9.32666 14.12 9.85999ZM14.3333 12.6667H1.66663V14H14.3333V12.6667Z"
        fill="black"
      />
    </svg>
  );
}
