import * as React from "react";

export function FastDelivery(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={21}
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.414.39H6.548a.234.234 0 00-.234.235v7.76a5.512 5.512 0 013.497 9.34h13.603c.13 0 .234-.105.234-.234V.625a.234.234 0 00-.234-.234z"
        fill="#FFC500"
      />
      <path
        d="M8.766 9.192V.625c0-.13.105-.234.234-.234H6.548a.234.234 0 00-.234.234v7.76a5.478 5.478 0 012.452.807z"
        fill="#EFB900"
      />
      <path
        d="M13.416.39v3.916c0 .077.063.14.14.14h2.85a.14.14 0 00.14-.14V.39h-3.13z"
        fill="#FD4755"
      />
      <path
        d="M14.906 4.306V.39h-1.49v3.915c0 .077.063.14.14.14h1.49a.14.14 0 01-.14-.14z"
        fill="#FB2B3A"
      />
      <circle cx={5.863} cy={13.879} r={5.512} fill="#EFB900" />
      <path
        d="M3.405 17.193a4.125 4.125 0 010-6.629 5.51 5.51 0 013.431-2.112 5.512 5.512 0 100 10.853 5.51 5.51 0 01-3.431-2.112z"
        fill="#FFC500"
      />
      <circle
        cx={5.861}
        cy={13.877}
        r={4.128}
        transform="rotate(-45 5.861 13.877)"
        fill="#fff"
      />
      <path
        d="M3.703 13.879a4.136 4.136 0 013.145-4.01 4.133 4.133 0 00-5.113 4.01 4.133 4.133 0 005.113 4.01 4.136 4.136 0 01-3.145-4.01z"
        fill="#F2FBFF"
      />
      <path
        d="M21.703 12.938H17.11a.14.14 0 01-.14-.141v-6a.14.14 0 01.14-.14h4.594a.14.14 0 01.14.14v6a.14.14 0 01-.14.14z"
        fill="#fff"
      />
      <path
        d="M18.375 12.797v-6a.14.14 0 01.14-.14H17.11a.14.14 0 00-.14.14v6c0 .077.063.14.14.14h1.407a.14.14 0 01-.141-.14z"
        fill="#F2FBFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.548.04h16.866c.323 0 .586.262.586.585v16.866a.587.587 0 01-.586.586h-1.242a.352.352 0 110-.703h1.125V.742h-6.399v.852a.352.352 0 11-.703 0V.742h-2.428v3.353h2.428v-.86a.352.352 0 01.703 0v1.07c0 .272-.22.493-.492.493h-2.85a.493.493 0 01-.492-.492V.742H6.666v1.515h1.687a.352.352 0 010 .703H6.666v1.138h2.662a.352.352 0 010 .703H6.666v1.138h3.6a.352.352 0 110 .703h-3.6V8.07a5.872 5.872 0 015.06 5.809 5.833 5.833 0 01-1.158 3.495h9.963a.352.352 0 110 .703H9.952a5.844 5.844 0 01-4.089 1.665A5.87 5.87 0 010 13.879a5.87 5.87 0 015.863-5.864l.083.001h.017V6.642H3.184a.352.352 0 010-.703h2.779V4.8H1.768a.352.352 0 010-.703h4.194V2.96H.353a.352.352 0 010-.703h5.61V.625c0-.323.263-.586.586-.586zm-.685 18.999a5.166 5.166 0 005.16-5.16 5.166 5.166 0 00-5.16-5.16 5.166 5.166 0 00-5.16 5.16 5.166 5.166 0 005.16 5.16z"
        fill="#2D2D2D"
      />
      <path
        d="M16.999 14.04a.352.352 0 100 .702h4.845a.352.352 0 000-.703h-4.845zM16.969 16.154h4.845a.352.352 0 100-.703h-4.845a.352.352 0 100 .703zM1.383 13.879c0 2.47 2.01 4.48 4.48 4.48.976 0 1.904-.31 2.685-.896a4.524 4.524 0 001.597-2.266.351.351 0 10-.672-.207 3.817 3.817 0 01-1.347 1.911 3.74 3.74 0 01-2.263.755 3.781 3.781 0 01-3.777-3.777 3.781 3.781 0 013.777-3.777c.94 0 1.84.348 2.537.98a3.77 3.77 0 011.22 2.407.352.352 0 00.7-.072 4.472 4.472 0 00-1.447-2.855 4.468 4.468 0 00-3.01-1.164c-2.47 0-4.48 2.01-4.48 4.48z"
        fill="#2D2D2D"
      />
      <path
        d="M5.863 10.447a.352.352 0 00-.351.351v3.08a.352.352 0 00.54.297l1.959-1.252a.352.352 0 00-.379-.593l-1.417.906v-2.438a.352.352 0 00-.352-.351z"
        fill="#2D2D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.703 6.305c.271 0 .492.22.492.492v6c0 .271-.22.492-.492.492H17.11a.493.493 0 01-.492-.492v-6c0-.272.221-.492.492-.492h4.594zm-4.383 6.28h4.172V7.009H17.32v5.578z"
        fill="#2D2D2D"
      />
      <path
        d="M18.145 9.285c.147.126.369.11.496-.037l.414-.48v1.676a.352.352 0 00.703 0V8.768l.414.48a.35.35 0 00.496.037.352.352 0 00.036-.496l-1.032-1.196a.351.351 0 00-.532 0l-1.032 1.196a.352.352 0 00.037.496zM18.287 12.123h2.238a.352.352 0 000-.703h-2.238a.352.352 0 100 .703z"
        fill="#2D2D2D"
      />
    </svg>
  );
}
