import * as React from "react";

export function FlightTakeOffIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4867 5.72C14.02 5.57334 14.5734 5.89334 14.7134 6.42667C14.8534 6.96 14.54 7.50667 14 7.66001C7.70002 9.34667 2.95335 10.6133 2.95335 10.6133L1.22668 7.62L2.19335 7.36L3.50668 8.38667L6.82002 7.5L4.06002 2.72L5.34668 2.38L9.94668 6.66667L13.4867 5.72ZM14.3334 12.6667H1.66668V14H14.3334V12.6667Z"
        fill="black"
      />
    </svg>
  );
}
