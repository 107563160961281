import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { theme } from './styles/theme';
import { MainLayout } from './components/Layout/MainLayout';
import { Protected, routes } from './routes';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useAuth } from './store/user';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'react-datepicker/dist/react-datepicker.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
'pk_live_q3GXmqh7oGsiPWarFTTVj5QK00GzRv97c7'
//'pk_test_vIlreIwSkRW9oRjjEb54XYAw00ZdkTKnzg'
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 2,
      retryOnMount: true,
      refetchOnMount: true,
      retry: false,
    },
  },
  
});
const mainRoutes = routes.map((route, index) => {
  return (
    <Protected
      key={index}
      name={route.name}
      exact={route.exact}
      path={route.path}
      component={route.component}
      requestedRole={route.requestedRole}
    />
  );
});
const app = (store: { initApp: any }) => store.initApp;
export const App = () => {
  const initApp = useAuth(app);

  useEffect(() => {
    initApp();
  }, [initApp]);

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <MainLayout>
              <Switch>{mainRoutes}</Switch>
    
            </MainLayout>
          </BrowserRouter>

        </Elements>
      </QueryClientProvider>
    </ChakraProvider>
    
  );
};
